import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Table } from "react-bootstrap";

import Meta from "../components/meta";
import Layout from "../components/layout";
import Submv from "../components/submv";

// markup
const MaintenancePage = ({ location }) => {
  return (
    <Layout>
      {/* サイトタイトル */}
      <Meta title="太陽光発電メンテナンス" />

      {/* <Submv title="太陽光発電メンテナンス" /> */}

      <div style={{ backgroundColor: "#f0f3ef", zIndex: 10, position: "relative" }}>
        <div className="container">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <a className="breadcrumb__link" href="/">
                  ホーム
                </a>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {" "}
                  &gt;{" "}
                </span>
              </li>
              <li className="breadcrumb__item">
                <a aria-current="page" className="breadcrumb__link" href="/maintenance/">
                  太陽光発電メンテナンス
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="sec position-relative bg-illust" style={{ backgroundColor: "#f0f3ef" }}>
        {/* <div className="illust illust--1">
          <StaticImage alt="illust" src="../images/illust_01.png" />
        </div>
        <div className="illust illust--2">
          <StaticImage alt="illust" src="../images/illust_02.png" />
        </div> */}
        <Container>
          <div className="text-center mb-md-5 mb-4">
            <StaticImage src="../images/img-fit.png" alt="太陽光発電メンテナンス" />
          </div>
          <h2 className="ttl--h2-2 text-primary kosugi text-center">FIT法が改正されました</h2>
          <p className="text-center" style={{ lineHeight: 2 }}>
            電気事業者による再生可能エネルギー電気の調達に関する特別措置法(通称FIT法)が改正され、
            <br />
            2017年4月より新制度に移行しております。
            <br />
            これに合わせて、お手続き方法を変更しております。
            <br />
            <span className="text-warning">※全ての太陽光発電設備をお持ちの方が対象です。(オフグリッド除く)</span>
          </p>
        </Container>
      </section>

      <section className="sec">
        <Container>
          <h3 className="font--xlarge font-weight-bold text-center">平安のO&Mサービスプラン内容</h3>
          <p className="font--large font-weight-bold text-warning text-center">（現在は、弊社にて太陽光を施工させて頂きました方のみご対応させて頂いております）</p>

          <div className="rounded bg-white overflow-hidden mt-5" style={{ border: "1px solid #3cb5d6" }}>
            <p className="font--large text-center bg-secondary text-white font-weight-bold m-0 py-2">低圧バリュープラン</p>
            <div className="py-3 px-3">
              <p className="text-center text-warning font-weight-bold m-0" style={{ fontSize: "150%" }}>
                年間<span style={{ fontSize: "200%" }}>25,000</span>円<small style={{ fontSize: "70%" }}>（税別）</small>
              </p>
              <div className="text-center">
                <p
                  className="d-inline-block my-2 font-weight-bold"
                  style={{
                    backgroundColor: "#fff4df",
                    padding: "0.25em 2em",
                  }}
                >
                  基本対応エリア：三重県・奈良県・滋賀県
                </p>
              </div>
              <ul>
                <li>発電状況を土日祝日・年末年始を含め365日監視（設置されている遠隔監視を利用）</li>
                <li>発電が異常と思われる場合、別途費用にて緊急対応。発電所所有者にお知らせ</li>
                <li>太陽光パネル・PCSの問題発生時のメーカー対応・保険会社対応</li>
                <li>経済産業省への年次点検業務</li>
                <li>
                  発電データの自動収集・発電量クラウド保存
                  <br />
                  （弊社のカスタマーページをログインして頂くことにより、いつでも過去の発電量をご覧頂くことができます）
                  <br />
                  ※シナネン低圧用メンテナンスサポートをご利用の場合、全国対応させて頂きます。現在のところ一部のお客様のみのサービスです。
                </li>
              </ul>
            </div>
          </div>

          <div
            className="rounded bg-white overflow-hidden mt-5"
            style={{
              border: "1px solid #3eb4bd",
            }}
          >
            <p
              className="font--large text-center text-white font-weight-bold m-0 py-2"
              style={{
                backgroundColor: "#3eb4bd",
              }}
            >
              低圧プレミアムプラン
            </p>
            <div className="py-3 px-3">
              <p className="text-center text-warning font-weight-bold m-0" style={{ fontSize: "150%" }}>
                年間<span style={{ fontSize: "200%" }}>39,800</span>円<small style={{ fontSize: "70%" }}>（税別）</small>
              </p>
              <div className="text-center">
                <p
                  className="d-inline-block my-2 font-weight-bold"
                  style={{
                    backgroundColor: "#fff4df",
                    padding: "0.25em 2em",
                  }}
                >
                  基本対応エリア：三重県・奈良県・滋賀県
                </p>
              </div>
              <ol>
                <li>発電状況を土日祝日・年末年始を含め365日監視（設置されている遠隔監視を利用）</li>
                <li>発電が異常と思われる場合、別途費用にて緊急対応。発電所所有者にお知らせ</li>
                <li>太陽光パネル・PCSの問題発生時のメーカー対応・保険会社対応</li>
                <li>経済産業省への年次点検業務</li>
                <li>
                  発電データの自動収集・発電量クラウド保存
                  <br />
                  （弊社のカスタマーページをログインして頂くことにより、いつでも過去の発電量をご覧頂くことができます）
                </li>
                <li>緊急時駆け付けサービス（年1回まで）</li>
                <li>
                  目視点検（1回/年）『パネル・架台・造成工事・フェンスの状態を確認』
                  <br />
                  パネル・・・ガラス面の汚れ（落葉等）、破損、障害物、フレームの破損、変形、紛失、盗難
                  <br />
                  架台・・・固定ボルトの確認、破損、腐食
                  <br />
                  ケーブル・・・断線、腐食、変形、イオン、異臭、配線の破損、断線、ファンの作動音の確認、積算電力量の確認
                  <br />
                  敷地内管理・・・不法投棄の確認、フェンスの破損、日陰障害物（草木）等の有無
                  <br />
                  ※点検日ご指定はお受け致しかねます。
                </li>
              </ol>
            </div>
          </div>

          <div className="mt-5">
            <h3 className="font--xlarge mb-4">
              <span className="font-weight-bold mr-md-3">オプションプラン</span>
              <small style={{ fontSize: "50%" }}>(対応エリア：三重県、奈良県、滋賀県現在、一部エリアのみ提供となっております)</small>
            </h3>
            <Table>
              <tbody>
                <tr>
                  <th>低圧現地駆け付け（故障のケースによりPCS電圧確認もさせて頂きます）</th>
                  <td className="text-right">25，000円（税別）１回</td>
                </tr>
                <tr>
                  <th>アイテス社ソラメンテにより点検業務</th>
                  <td className="text-right">35，000円（税別）１回</td>
                </tr>
                <tr>
                  <th>直流側の絶縁抵抗、電流・電圧、IVカーブ、インピーダンスの測定別途お見積り</th>
                  <td className="text-right">別途お見積り</td>
                </tr>
                <tr>
                  <th>
                    シナネンメンテナンスサポート（全国対応年2回目視点検）
                    <br />
                    （現在のところ一部のお客様のみのサービス提供となっております）
                  </th>
                  <td className="text-right">初期費用 50，000円（税別）年間 34，800円（税別）</td>
                </tr>
                <tr>
                  <th>低圧草刈工事（刈り倒し）35，000円（税別）１回</th>
                  <td className="text-right">35，000円（税別）１回</td>
                </tr>
                <tr>
                  <th>除草剤安心パック</th>
                  <td className="text-right">70，000円（税別）年間</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default MaintenancePage;
